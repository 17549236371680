<bfc-dialog title='{{ "NOTIFICATION.TITLE" | bfcTranslate }}'>

    <ng-container bfc-dialog-content>
        <p>{{"NOTIFICATION.TEXT" | bfcTranslate }}</p>
        <form [formGroup]="formGroup">
            <div class="bfc-form-row">
                <mat-form-field>
                    <mat-label>{{ "NOTIFICATION.EMAIL" | bfcTranslate }}</mat-label>
                    <input matInput type="email" formControlName="email" required>
                </mat-form-field>
            </div>
            <div class="bfc-form-row">
                <mat-checkbox formControlName="active">
                    {{ 'NOTIFICATION.ACTIVE' | bfcTranslate }}
                </mat-checkbox>
            </div>
        </form>
    </ng-container>

    <ng-container bfc-dialog-actions>
        <button mat-button mat-dialog-close>{{"NOTIFICATION.CANCEL" | bfcTranslate }}</button>
        <button mat-flat-button
            (click)="save()" [disabled]="!formGroup.valid" [loading]="isUpdatingInBackend">
            {{"NOTIFICATION.SAVE" | bfcTranslate }}
        </button>
    </ng-container>


</bfc-dialog>
