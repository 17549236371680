import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { BfcTranslationModule } from "@bfl/components/translation";
import { UnauthorizedComponent } from "./unauthorized.component";
import { RouterModule } from "@angular/router";
import { unauthorizedRoutes } from "./unauthorized.routes";
import { BfcIconModule } from "@bfl/components/icon";

@NgModule({
  imports: [
    BfcSinglePageLayoutModule,
    BfcTranslationModule,
    CommonModule,
    RouterModule.forChild(unauthorizedRoutes),
    BfcIconModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  declarations: [
    UnauthorizedComponent,
  ],
})
export class UnauthorizedModule {
}