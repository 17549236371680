import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { map, mergeMap, share } from "rxjs/operators";
import { Organisation } from "../model/organisation";
import { forkJoin, of } from "rxjs";
import { Service } from "../model/service";

@Injectable()
export class SelfServiceOpIamService {

  private organisations: Organisation[];

  private organisationsObservable: Observable<Organisation[]>;

  public organisationChanged: EventEmitter<Organisation> = new EventEmitter<Organisation>();

  private selectedOrganisation: Organisation;

  constructor(private httpClient: HttpClient,
    private configurationService: BfcConfigurationService) {

  }

  public getCurrentOrganisation(): Observable<Organisation> {
    if (this.selectedOrganisation) {
      return new Observable<Organisation>(observer => {
        observer.next(this.selectedOrganisation);
        observer.complete();
      });
    } else {
      return this.getOrganisations().pipe(
        map(organisations => {
          this.selectedOrganisation = organisations[0];
          return this.selectedOrganisation;
        }),
      );
    }
  }

  public setSelectedOrganisation(organisation: Organisation): void {
    this.selectedOrganisation = organisation;
    this.organisationChanged.emit(organisation);
  }

  private getOrganisationServices(id: string): Observable<Service[]> {
    return this.httpClient.get(
      `${this.configurationService.configuration.opSelfServiceUrl}/me/organisations/${id}/services`,
    ).pipe(
      map((response: any): Service[] => {
        if (response._embedded?.services) {
          return response._embedded.services;
        }
        return [];
      }),
      share(),
    );
  }

  public getOrganisations(): Observable<Organisation[]> {
    if (this.organisations !== undefined) {
      // return loaded organisations
      return new Observable<Organisation[]>((obs) => {
        obs.next(this.organisations);
        obs.complete();
      });
    } else {
      // organisations are not being loaded yet
      if (!this.organisationsObservable) {
        // load organisations
        this.organisationsObservable = this.httpClient.get(this.configurationService.configuration.opSelfServiceUrl
                    + "/me/organisations").pipe(
          mergeMap((response: any): Observable<Organisation[]> => {
            // Only filter organisations, if there are any
            if (response._embedded?.organisations) {
              this.organisations = response._embedded.organisations;
              const services = [];
              const filteredOrganisations = [];
              this.organisations.forEach(org => services.push(this.getOrganisationServices(org.commonOrganisationId)));

              // Wait for services to finish loading
              return forkJoin(services).pipe(map((allServices: any): Organisation[] => {
                this.organisations.forEach((org, i) => {
                  org.services = allServices[i];
                  if (org.services?.some((service: Service) => {
                    return service.code === "ROLE_TSST";
                  })) {
                    filteredOrganisations.push(org);
                  }
                });
                this.organisations = filteredOrganisations;
                return this.organisations;
              }),
              );
            }
            return of([]);
          }),
          share(),
        );
      }
      // return organisations request
      return this.organisationsObservable;
    }
  }

  /**
     * Gets the EICX if present, otherwise the organisationId a.k.a. MasterHub ID.
     *
     * @param organisation the organisation
     * @returns the EICX if present, otherwise the organisation Id
     */
  public getId(organisation: Organisation): string {
    if (organisation.foreignKeys.eicx) {
      return organisation.foreignKeys.eicx;
    }
    return organisation.commonOrganisationId;
  }

}
