import { NgModule } from "@angular/core";
import { BfcButtonModule } from "@bfl/components/button";
import { BfcDialogModule } from "@bfl/components/dialog";
import { BfcFormModule } from "@bfl/components/form";
import { BfcFormFieldModule } from "@bfl/components/form-field";
import { BfcInputModule } from "@bfl/components/input";
import { BfcLoadingModule } from "@bfl/components/loading";
import { BfcTranslationModule } from "@bfl/components/translation";
import { NotificationDialogComponent } from "./notification-dialog/notification-dialog.component";
import { CommonModule } from "@angular/common";
import { BfcCheckboxModule } from "@bfl/components/checkbox";

@NgModule({
  imports: [
    CommonModule,
    BfcTranslationModule,
    BfcButtonModule,
    BfcDialogModule,
    BfcInputModule,
    BfcCheckboxModule,
    BfcFormFieldModule,
    BfcFormModule,
    BfcLoadingModule,
  ],
  declarations: [
    NotificationDialogComponent,
  ],
})
export class NotificationModule {
}
