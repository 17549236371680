<bfc-single-page-layout>
    <a class="bfc-margin-bottom-2" (click)="goBack()">
        <span class="b2b-back-button">«</span>{{ "BACK_BUTTON" | bfcTranslate }}
    </a>
    <div class="bfc-margin-left-5">
        <mat-icon fontIcon="bfc-icon-key" class="unauthorized-key"></mat-icon>
    </div>
    <div class="unauthorized-title">{{ "UNAUTHORIZED.TITLE" | bfcTranslate }}</div>
    <div class="unauthorized-description">{{ "UNAUTHORIZED.DESCRIPTION" | bfcTranslate }}</div>
    <a class="bfc-margin-bottom-2 bfc-margin-top-2" (click)="goBack()">
        <span class="b2b-back-button">«</span>{{ "BACK_BUTTON" | bfcTranslate }}
    </a>
</bfc-single-page-layout>
