import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcTranslationService } from "@bfl/components/translation";
import moment from "moment";
import { Observable, ReplaySubject, Subject, timer } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { TrafoStatus } from "../model/TrafoStatus";
import { Status } from "../model/Status";
import { Substation } from "../model/Substation";
import { Organisation } from "../model/organisation";
import { SelfServiceOpIamService } from "./self-service-op-iam.service";

const DATETIME_FORMAT: string = "DD.MM.YYYY HH:mm";

@Injectable()
export class SubstationService implements OnDestroy {

  // reload status every minute
  private interval = 60 * 1000;

  private unsubscribe: Subject<void> = new Subject<void>();

  private substationListSubject: ReplaySubject<Substation[]> = new ReplaySubject<Substation[]>(1);

  private substationLoadErrorSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  private trafoStationApi;

  constructor(
    private httpClient: HttpClient,
    private bfcTranslationService: BfcTranslationService,
    private selfServiceOpIamService: SelfServiceOpIamService,
    bfcConfigurationService: BfcConfigurationService,
  ) {
    this.trafoStationApi = bfcConfigurationService.configuration.trafoStationApiUrl;

    this.substationLoadErrorSubject.next(false);

    this.selfServiceOpIamService.organisationChanged.pipe(takeUntil(this.unsubscribe)).subscribe(organisation => {
      this.loadSubstationStatusList(organisation);
    });

    timer(0, this.interval).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.triggerStatusListLoad();
    });

  }

  private triggerStatusListLoad(): void {
    this.selfServiceOpIamService.getCurrentOrganisation().pipe(take(1)).subscribe((organisation: Organisation) => {
      this.loadSubstationStatusList(organisation);
    });
  }

  get substationList(): Observable<Substation[]> {
    return this.substationListSubject.asObservable();
  }

  get trafoStatusLoadError(): Observable<boolean> {
    return this.substationLoadErrorSubject.asObservable();
  }

  loadSubstationStatusList(organisation: Organisation) {
    const params = new HttpParams().set("eicx", this.selfServiceOpIamService.getId(organisation));
    this.httpClient.get<Substation[]>(this.trafoStationApi + "/substations", { params }).pipe(take(1)).subscribe(
      data => {
        this.substationListSubject.next(data);
        this.substationLoadErrorSubject.next(false);
      }, () => {
        // push to error subject
        this.substationLoadErrorSubject.next(true);
      },
    );
  }

  formatStatus(trafoStatus: TrafoStatus): string {
    switch (trafoStatus.status) {
      case Status.OFFLINE:
        return this.bfcTranslationService.translate("STATUS.OFFLINE",
          { datetime: moment(this.createMomentDate(trafoStatus.ausfallBeginn)).format(DATETIME_FORMAT) });
      case Status.RECENTLY_OFFLINE:
        return this.bfcTranslationService.translate("STATUS.RECENTLY_OFFLINE",
          { datetime: moment(this.createMomentDate(trafoStatus.ausfallEnde)).format(DATETIME_FORMAT) });
      case Status.ONLINE:
      default:
        return this.bfcTranslationService.translate("STATUS.ONLINE");
    }
  }

  private createMomentDate(dateObject: any): object {
    if (dateObject) {
      return {
        year: dateObject.year,
        month: dateObject.monthValue,
        day: dateObject.dayOfMonth,
        hour: dateObject.hour,
        minute: dateObject.minute,
      };
    }
    return null;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
