import { AppRoutingModule } from "./app-routing.module";
import { OverlayModule } from "@angular/cdk/overlay";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BfcDialogModule } from "@bfl/components/dialog";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
  BfcAuthenticationInterceptor,
  BfcAuthenticationModule,
} from "@bfl/components/authentication";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { BFC_TRANSLATIONS, BfcTranslationModule, BfcTranslationService } from "@bfl/components/translation";
import { translations } from "./config/translations/translations";
import { BFC_CONFIGURATIONS, BfcConfigurationModule } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { configuration } from "./config/configuration";
import { CoreModule } from "./core/core.module";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { NotificationModule } from "./notification/notification.module";
import { InfoWindowComponent } from "./components/info-window/info-window.component";
import { UnauthorizedModule } from "./unauthorized/unauthorized.module";
import { BfcNotificationService } from "@bfl/components/notification";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { initializeGoogleMaps } from "./core/initialization/initializer-googlemaps";
import { BfcIconModule } from "@bfl/components/icon";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BfcTranslationModule,
    BfcGoogleAnalyticsModule,
    BfcConfigurationModule,
    BfcAuthenticationModule,
    BfcSinglePageLayoutModule,
    CoreModule,
    OverlayModule,
    BfcDialogModule,
    NotificationModule,
    BfcIconModule,
    UnauthorizedModule,
  ],
  declarations: [
    AppComponent,
    InfoWindowComponent,
  ],
  providers: [
    BfcNotificationService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeGoogleMaps,
      multi: true,
    },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
  constructor(private bfcTranslateService: BfcTranslationService) {
    this.loadGoogleMapScript(bfcTranslateService.language);
  }

  private loadGoogleMapScript(language: string) {
    // previously used key = "AIzaSyDX_WN1MAOJ6wWEqJWfuG0cxts54wiqoGI";
    const key = "AIzaSyDyvSvXvSmKZeRYnG6jjrsvnxI1WYUIR_c";   // this key is taken from NVT app
    const scriptSrc = "https://maps.google.com/maps/api/js?language=" + language + "&key=" + key + "&callback=googleMapsCallback";
    const scriptTag = document.createElement("script");
    scriptTag.setAttribute("src", scriptSrc);
    scriptTag.setAttribute("async", "");
    document.head.appendChild(scriptTag);
  }
}
